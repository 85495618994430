import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import OpenLink from "./OpenLink";
import {
  OpenLinkCreator,
  setLinkCreator,
  setFullLinkCreator,
  OpenDoorCreator,
  updateLoading,
  setFlagOpen,
  updateLoadingPage,
} from "../../../redux/openlink-reducer";
import { useEffect } from "react";
//
const OpenLinkContainer = ({
  openlink,
  OpenLinkCreator,
  setLinkCreator,
  setFullLinkCreator,
  OpenDoorCreator,
  updateLoading,
  setFlagOpen,
  updateLoadingPage,
}) => {
  useEffect(() => {
    //const linkhash = `https://d-keys.info/open/?token=MOL51G6yGCRnxi8tZz8MlIjzlCSF0gKcXM3B7qVhK9twaTtfOsXsIzg2GHP48O3J`
    const linkhash = window.location.href;
    setLinkCreator(linkhash);
  }, [setLinkCreator]);

  useEffect(() => {
    if (openlink.link) {
      chekLinkActive();
    }
  }, [openlink.link, OpenLinkCreator]);


  const chekLinkActive = () => {
    updateLoadingPage();
    OpenLinkCreator(openlink.link);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (openlink.link) {
        updateLoadingPage();
        OpenLinkCreator(openlink.link);
      }
     }, 10800000); // 3 часа

  }, [openlink.link, OpenLinkCreator, updateLoadingPage]); 

  useEffect(() => {
    if (openlink.fullLink && !openlink.response.error) {
      OpenDoorCreator(openlink.fullLink);
    } else {
      console.log("проблема");
    }
  }, [openlink.fullLink, openlink.response.error]);

  const setFullLink = (value) => {
    setFullLinkCreator(value);
  };

  const FlagOpen = () => {
    setFlagOpen();
  };

  return (
    <OpenLink
      state={{ openlink }}
      setFullLink={setFullLink}
      FlagOpen={FlagOpen}
      chekLinkActive = {chekLinkActive}
    />
  );
};
const mapStateToProps = (state) => ({ openlink: state.openlink });

const dispatch = {
  OpenLinkCreator,
  setLinkCreator,
  setFullLinkCreator,
  OpenDoorCreator,
  updateLoading,
  setFlagOpen,
  updateLoadingPage,
};

export default compose(connect(mapStateToProps, dispatch))(OpenLinkContainer);
