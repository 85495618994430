import React, { useEffect, useState } from "react";
import s from "./OpenLink.module.css";
import NotFound from "../../common/NotFound/NotFound";
import Loading from "../../common/loading/Loading";

const OpenLink = (props) => {
  const [error, setError] = useState("");
  const [openDoor, setOpenDoor] = useState(false);
  const [openButtonNewClass, setOpenButtonNewClass] = useState("");
  const [loading, setloading] = useState("");

  const OpenDoor = () => {
    if (openDoor) return;
    if (props.state.openlink.response.DATA != null) {
      setOpenDoor(true);
      props.chekLinkActive();
      props.setFullLink(props.state.openlink.response.DATA.link);
      setloading(s.onclic);
      if (props.state.openlink.isLoading == true) {
        setOpenButtonNewClass(s.validate);
      }
    }
  };

  const resultOpenDoor = () => {
    if (props.state.openlink.flag == true) {
      if (props.state.openlink.responseOpenDoor.error == true) {
        setOpenButtonNewClass(s.openbuttonerror);
        setloading(s.onclicOk);
        setError(props.state.openlink.responseOpenDoor.message);
      } else {
        setOpenButtonNewClass(s.doorOpen);
        setloading(s.onclickOk);
      }

      setTimeout(() => {
        setOpenButtonNewClass("");
        setloading("");
        setOpenDoor(false);
        props.FlagOpen();
        setError("");
      }, 7000);
    }
  };

  useEffect(() => {
    resultOpenDoor();
  }, [props.state.openlink.flag]);

  if (props.state.openlink.loadindPage == true) {
    return (
      <div className={s.loading}>
        <Loading />
      </div>
    );
  }

  if (props.state.openlink.response.length != 0) {
    if (
      props.state.openlink.response.message ===
        "Опаньки, какие-то не предвиденные ошибки :(" ||
      props.state.openlink.response.message === "13.4.2 - Not Found 404"
    ) {
      return (
        <div className={`${s.errorContainer}`}>
          <NotFound />
        </div>
      );
    } else if (props.state.openlink.response) {
      return (
        <div className={`${s.mainContainer}`}>
          <div className={s.container}>
            <div
              className={`${
                props.state.openlink.response.error === true || error
                  ? `${s.opencontainerError}`
                  : `${s.opencontainer}`
              }`}
            >
              {props.state.openlink.response.error === true || error ? (
                <p className={s.messageError}>
                  {props.state.openlink.response.message
                    ? props.state.openlink.response.message
                    : error}
                </p>
              ) : (
                <p className={s.messageOk}>Press to open</p>
              )}
              <button
                disabled={
                  props.state.openlink.response.error === true ? true : false
                }
                onClick={() => {
                  OpenDoor();
                }}
                className={` ${
                  props.state.openlink.response.error
                    ? `${s.openbuttonerror}`
                    : `${s.openbutton} ${openButtonNewClass}`
                }`}
              >
                <div className={loading}></div>
              </button>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className={`${s.errorContainer}`}>
        <NotFound />
      </div>
    );
  }
};

export default OpenLink;
